import { Img } from "@onnit-js/ui/components/image";
import Box from "@onnit-js/ui/components/box/Box";
import { Text, Sup } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import Hero from "@onnit-js/ui/components/module/Hero";
import { useMedia } from "@onnit-js/ui/components/media";
import React from "react";
import { HeroProps } from "../../../interfaces/Hero";
import usePromotionEvent from "../../../hooks/usePromotionEvent";

export default ({ baseUrl, imgParams, position }: HeroProps) => {
    const isMobile = useMedia({ query: "(max-width: 1039px)" });
    const onClick = usePromotionEvent("Hero - Alpha BRAIN Collection", position);

    const url = "/alpha-brain-collection/";
    return (
        <Hero
            variant="reverse"
            pb={[6, 6, 6, 8]}
            href={url}
            image={(
                <Img
                    src={`${baseUrl}/AB-Collection-2022.jpg${imgParams}`}
                    alt="Alpha BRAIN products displayed on top of a photo of a gamer."
                    lazyLoad={false}
                    height={1448}
                    width={2608}
                />
            )}
            onClick={onClick}
        >
            {isMobile && (
                <Box px={[4, 4, 0]}>
                    <Text as="h1" typeStyle="copyDisplay02" fontSize={[4, 4, 5, 5, 6, 6, 6]} mb={3}>
                        The Alpha BRAIN
                        <Sup variant="reg" /> Collection Is Now Available.
                    </Text>
                    <Text as="p" typeStyle="body" color="grays.4" mb={5}>
                        Find the focus and thinking formula that's right for you.
                        <Sup variant="dagger" />{" "}
                    </Text>
                    <Button el="a" href={url} width={1} aria-label="Shop Alpha BRAIN Collection." onClick={onClick}>
                        Shop Now
                    </Button>
                </Box>
            )}
        </Hero>
    );
};
