import React, { FormEvent, useState } from "react";
import Button from "@onnit-js/ui/components/button/Button";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FaChevronRight } from "react-icons/fa";
import TextField from "@onnit-js/ui/components/form/textfield/TextField";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { EmailLeadSourceEnum, EmailListEnum } from "@onnit-js/ui/@types/enums/EmailList";
import MessageBox, { MessageLevelEnum } from "@onnit-js/ui/components/notification/MessageBox";
import { isValidEmail } from "../../lib/utils";

interface Props extends BoxProps {
    lists?: EmailListEnum | EmailListEnum[];
    leadSource?: EmailLeadSourceEnum;
    onSuccess?: Function;
    successMessage?: string;
}

const EmailListSignUp: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (
    {
        lists = EmailListEnum.ONNIT_NEWSLETTER,
        leadSource = EmailLeadSourceEnum.ONNIT_NEWSLETTER,
        onSuccess,
        successMessage = "Thank you for subscribing",
        ...rest
    }: Props) => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null);
        if (!isValidEmail(email)) {
            setError("Please enter a valid email address");
            return;
        }

        if (!window.ONNIT?.hubspot) {
            console.error("Hubspot is not defined");
            return;
        }

        const result = window.ONNIT.hubspot.emailListSignup(email, lists, leadSource);
        if (result) {
            setSuccess(true);
            onSuccess?.();
        } else {
            console.error("Error submitting form");
        }
    };

    return (
        <Box {...rest}>
            {success ? (
                <MessageBox level={MessageLevelEnum.SUCCESS} message={successMessage} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <Box display="flex" alignItems="flex-start">
                        <TextField
                            m={0}
                            flex="1"
                            placeholder="Email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value.trim())}
                            mr={3}
                            error={error}
                        />
                        <Button size="medium" type="submit" disabled={!isValidEmail(email)}>
                            <span className="sr-only" style={{ color: "black" }}>
                                Submit
                            </span>
                            <Icon icon={FaChevronRight} color="white" aria-hidden="true" />
                        </Button>
                    </Box>
                </form>
            )}
        </Box>
    );
};

export default EmailListSignUp;
