import { Card, Cards } from "@onnit-js/ui/components/module/Cards";
import { Text } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import React from "react";
import featuredContentArticles from "../articles/featuredContentArticles";
import { todayInDateRange } from "../../../lib/utils";
import { FeaturedContentArticle } from "../../../interfaces/FeaturedContentArticle";

const FeaturedArticles = (
    { articles, baseUrl, imgParams }:
        {
            articles: FeaturedContentArticle[],
            imgParams: string,
            baseUrl: string
        }) => {
    const cards = articles.map((article) => (
        <Card
            key={article.position}
            span={[1, 2].includes(article.position) ? 6 : undefined}
            href={article.url}
            bgSrc={`${baseUrl}/cards/${article.image}${imgParams}`}
        >
            <Text fontWeight="heavy" fontSize={[2, 6, 4, 4, 5, 5, 6]} color="white">
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: article.title }} />
                <Text className="sr-only">.</Text>
            </Text>
            <Button el="span" size="small" raised={false} color="white" textColor="black">
                Read More
            </Button>
        </Card>
    ));

    return <>{cards}</>;
};

export default ({ textColor = "black", baseUrl, imgParams }: {
    textColor?: string;
    baseUrl: string;
    imgParams: string;
}) => {
    const fallbackArticles = featuredContentArticles
        .filter((article) => !article.startDate && !article.endDate);
    const scheduledArticles = featuredContentArticles
        .filter((article) => article.startDate
            && article.endDate
            && todayInDateRange(article.startDate, article.endDate));

    let articles = fallbackArticles;
    if (scheduledArticles.length) {
        // Replace articles at specific position with scheduled article if one exists, otherwise use the fallback article at position
        articles = fallbackArticles.map((article) => scheduledArticles.find((scheduledArticle) => scheduledArticle.position === article.position) || article);
    }
    return (
        <Cards
            px={4}
            py={[6, 6, 8]}
            before={(
                <Text color={textColor} as="h2" fontWeight="heavy" fontSize={5} mb={4}>
                    Featured Content
                </Text>
            )}
        >
            <FeaturedArticles articles={articles} baseUrl={baseUrl} imgParams={imgParams} />
        </Cards>
    );
};
