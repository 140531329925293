import { createRoot } from "react-dom/client";
import React from "react";

import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import FallbackPage from "./FallbackPage";
import usePageData from "../../hooks/usePageData";
import ContentfulHomePage from "./ContentfulHomePage";
import ContentfulPage from "../../components/contentful/ContentfulPage";

const Comp = () => {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    if (!onnitContext) {
        return null;
    }
    const isDisruptiveSale = onnitContext.disruptive_sale.is_active;

    // Checking for string 'null' as an extra precaution
    if (pageData?.contentJson && pageData.contentJson !== "null") {
        return isDisruptiveSale ? <ContentfulPage /> : <ContentfulHomePage />;
    }

    return <FallbackPage />;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById("page-content")!);
root.render(<Comp />);
