import React from "react";
import { Text, Sup } from "@onnit-js/ui/components/text";
import { Img } from "@onnit-js/ui/components/image";
import Button from "@onnit-js/ui/components/button/Button";
import Feature from "@onnit-js/ui/components/module/Feature";
import { FeatureProps } from "../../../interfaces/Feature";
import usePromotionEvent from "../../../hooks/usePromotionEvent";

export default ({ baseUrl, imgParams, position }: FeatureProps) => {
    const onClick = usePromotionEvent("Feature - New MOOD", position);

    return (
        <Feature
            px={4}
            py={[6, 6, 6, 8]}
            href="/new-mood"
            heading={(
                <Text as="h2" typeStyle="copyDisplay02" fontSize={[4, 4, 5, 5, 6, 6, 6]}>
                    A REAL CHILL PILL
                </Text>
            )}
            image={<Img alt="New MOOD®" src={`${baseUrl}/new-mood.jpeg${imgParams}`} placeholder={[2368, 1040]} />}
            cta={(
                <Button el="a" href="/new-mood" width={1} aria-label="Shop New MOOD." onClick={onClick}>
                    SHOP NOW
                </Button>
            )}
            content={(
                <Text as="p" typeStyle="body" color="grays.4">
                    New MOOD
                    <Sup variant="reg" /> is the amp antidote, helping you relax and focus on yourself by turning down
                    the noise of the chaos.
                    <Sup />
                </Text>
            )}
            onClick={onClick}
        />
    );
};
